<template>
  <b-container fluid>
    <bo-page-title />
    <h1>Test Page</h1>
    <div class="col-md-4">
        <Uploader v-model="video" type="vyoutube"/>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
export default {
  extends: GlobalVue,
  data() {
    return {
        dataTest : {},
        video: ""
    }
  },
  methods: {
  },
  mounted() {
  },
  watch:{
  }
}
</script>